@import url("https://fonts.googleapis.com/css2?family=Quicksand:wght@400;700&family=Roboto+Condensed:wght@400;700&display=swap");

* {
  box-sizing: border-box;
}

body {
  font-family: "Quicksand", sans-serif;
  line-height: 1.5;
  margin: 0;
  padding: 0;
  background-color: #f5f7fa;
  color: #333333;
}
.cta-btn {
  font-family: "Quicksand", sans-serif;
  background-color: #FFDD00;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  color: white;
  font-size: 1em;
  cursor: pointer;
  color: #333333;
}
.cta-btn:hover {
  background-color: #efad09;
}