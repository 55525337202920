.hero {
    text-align: center;
    padding: 100px 20px;
    background: linear-gradient(to right, #870BEF, #D79CFF);
    color: white;
}

.hero h1 {
    font-size: 2.5em;
}

.hero p {
    font-size: 1.2em;
    margin: 20px 0;
}

.hero img {
    max-width: 20%;
    border-radius: 15%;
}