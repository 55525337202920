.features {
    padding: 60px 40px;
    text-align: center;
}
.features h2 {
    font-size: 2em;
    margin-bottom: 20px;
}
.features .feature-item {
    display: inline-block;
    width: 30%;
    margin: 10px;
    padding: 20px;
    border: 1px solid #ddd;
    border-radius: 8px;
    background-color: #fff;
}