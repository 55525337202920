header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px 40px;
    background-color: #6A0DAD;
    color: white;
}

header .logo {
    font-size: 1.5em;
    font-weight: bold;
}

nav a {
    margin: 0 15px;
    color: white;
    text-decoration: none;
    font-size: 1em;
}

nav a:hover {
    text-decoration: underline;
}

.header img {
    width: 70px;
    height: 70px;
    background: #555;
    margin-right: 10px;
    display: inline;
}

.header h1 {
    display: inline;
}

