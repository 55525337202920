footer {
    padding: 20px 40px;
    background-color: #6A0DAD;
    color: white;
    text-align: center;
}
footer a {
    color: #fff200;
    text-decoration: none;
}
footer a:hover {
    text-decoration: underline;
}